<div class="main-container">
  <div id="lplvc-print-section" class="horizontal-printable-version">
    <div class="load-plan-header-section-class shipper-section">
      <div class="container-15-percent">
        <img
          class="shipper-logo-class logo-margin"
          alt="Shipper Logo"
          src='{{ logo }}'
          id="lplvc-logo">
      </div>
      <div class="container-20-percent load-plan-headers-margin">
        <p class="title-load-plan-header-class general-title-margin">{{ 'loadPlanTags.title' | translate }}</p>
        <p class="header-subtitle-class">{{ shipmentData.shipmentId }}</p>
      </div>
      <div class="container-20-percent load-plan-headers-margin">
        <p class="title-load-plan-header-class general-title-margin">{{ 'loadPlanTags.creationDate' | translate }}</p>
        <p class="header-subtitle-class">{{ loadPlanService.setDate(shipmentData.creationDate) }}</p>
      </div>
      <div class="container-25-percent load-plan-headers-margin date-min-margin">
        <p class="title-load-plan-header-class general-title-margin">{{ 'loadPlanTags.modificationDate' | translate }}</p>
        <p class="header-subtitle-class">{{ loadPlanService.setDate(shipmentData.lastUpdate) }}</p>
      </div>
      <div class="container-20-percent load-plan-headers-margin">
        <p class="title-load-plan-header-class general-title-margin">{{ 'loadPlanTags.printDate' | translate }}</p>
        <p class="header-subtitle-class">{{ loadPlanService.setDate(currentDate) }}</p>
      </div>
    </div>

    <div class="container-100-percent-no-info flex-class" *ngIf="!shouldHideRequirementsSection">
      <div class="container-70-percent">
        <!-- General data and total merchandise info section -->
        <div class="first-header-section-class">
          <div class="container-50-percent">
            <div>
              <div class="general-data-title">
                <p class="title-header-class compact-characters-size bold-font-class header-first-section-title-margin">
                  {{ 'loadPlanTags.generalData' | translate }}</p>
              </div>
              <div class="flex-class">
                <div class="container-33-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">{{ 'loadPlanTags.accounts' | translate }}</p>
                  <p class="subtitle-class">{{ shipmentService.setAllAccountsInShipment(fullOrdersData) }}</p>
                </div>
                <div class="container-33-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">{{ 'loadPlanTags.tripType' | translate }}</p>
                  <p class="subtitle-class">{{ loadPlanService.setShipmentTripTypeLabel(shipmentData.tripType) }}</p>
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">{{ 'loadPlanTags.internalReference' | translate }}</p>
                  <p class="subtitle-class break-word-class">{{ shipmentData.internalReference ? shipmentData.internalReference : 'loadPlanTags.noInfo' | translate }}</p>
                </div>
                <div class="container-33-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">
                    {{ 'loadPlanTags.shipmentServiceType' | translate }}
                  </p>
                  <p class="subtitle-class">{{ loadPlanService.setShipmentServiceType(shipmentData.shipmentServiceType) }}</p>
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">{{ 'loadPlanTags.shipmentLoadType'| translate }}</p>
                  <p class="subtitle-class">{{ loadPlanService.setShipmentLoadType(shipmentData.loadType) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="container-50-percent">
            <div>
              <div class="general-data-title">
                <p class="title-header-class compact-characters-size bold-font-class header-first-section-title-margin">
                  {{ 'loadPlanTags.totalMerchandise' | translate }}
                </p>
              </div>
              <div class="flex-class align-items-to-end merchandise-icons-top-margin">
                <div class="container-25-percent center-text">
                  <img class="merchandise-icon-class" src="{{ stopsIcon }}" alt="Icon for stops">
                  <div class="merchandise-font-class">
                    {{ !loadPlanService.isAGreatNumericValue(shipmentData.ordersByStop.length) ?
                    (shipmentData.ordersByStop.length | number:'1.0':'en-US') :
                    loadPlanService.handleBigQuantities(shipmentData.ordersByStop.length) }}
                  </div>
                  <p class="merchandise-font-margin-class">{{ 'loadPlanTags.stops' | translate }}</p>
                </div>
                <div class="container-25-percent center-text">
                  <img class="merchandise-icon-class" src="{{ ordersIcon }}" alt="Icon for orders">
                  <div class="merchandise-font-class">
                    {{ !loadPlanService.isAGreatNumericValue(shipmentData.orders.length) ?
                    (shipmentData.orders.length | number:'1.0':'en-US') :
                    loadPlanService.handleBigQuantities(shipmentData.orders.length) }}
                  </div>
                  <p class="merchandise-font-margin-class">{{ 'loadPlanTags.orders' | translate }}</p>
                </div>
                <div class="container-25-percent center-text">
                  <img class="merchandise-icon-class" src="{{ palletsIcon }}" alt="Icon for pallets">
                  <div class="merchandise-font-class">
                    {{ !loadPlanService.isAGreatNumericValue(shipmentData.pallets) ?
                    (shipmentData.pallets | number:'1.0':'en-US') :
                    loadPlanService.handleBigQuantities(shipmentData.pallets) }}
                  </div>
                  <p class="merchandise-font-margin-class">{{ 'loadPlanTags.pallets' | translate }}</p>
                </div>
                <div class="container-25-percent center-text">
                  <img class="merchandise-icon-class" src="{{ boxesIcon }}" alt="Icon for boxes">
                  <div class="merchandise-font-class">
                    {{ !loadPlanService.isAGreatNumericValue(shipmentData.boxes) ?
                    (shipmentData.boxes | number:'1.0':'en-US') :
                    loadPlanService.handleBigQuantities(shipmentData.boxes) }}
                  </div>
                  <p class="merchandise-font-margin-class">{{ 'loadPlanTags.boxes' | translate }}</p>
                </div>
                <div class="container-25-percent center-text">
                  <img class="merchandise-icon-class" src="{{ piecesIcon }}" alt="Icon for pieces">
                  <div class="merchandise-font-class">
                    {{ !loadPlanService.isAGreatNumericValue(shipmentData.pieces) ?
                    (shipmentData.pieces | number:'1.0':'en-US') :
                    loadPlanService.handleBigQuantities(shipmentData.pieces) }}
                  </div>
                  <p class="merchandise-font-margin-class">{{ 'loadPlanTags.pieces' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Shipment transport info section -->
        <div class="second-header-section-class">
          <div class="container-100-percent main-margin">
            <div>
              <div class="general-data-title">
                <p class="title-header-class compact-characters-size bold-font-class header-second-section-title-margin">{{
                  'loadPlanTags.assignedTransportation' | translate }}</p>
              </div>
              <div class="flex-class">
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">{{ 'loadPlanTags.carrier' |
                    translate }}
                  </p>
                  <p class="subtitle-class">{{ shipmentData?.transport?.transportCarrier ? shipmentData.transport.transportCarrier :
                  'loadPlanTags.doesNotApply' | translate }}
                  </p>
                </div>
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">{{ 'loadPlanTags.vehicleType' | translate }}</p>
                  <p class="subtitle-class">{{ shipmentData?.transport?.vehicle ? shipmentData?.transport?.vehicle :
                    'loadPlanTags.doesNotApply' | translate}}
                  </p>
                </div>
                <div class="container-10-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin plates-margin-left">{{ 'loadPlanTags.plates' | translate }}</p>
                  <p class="subtitle-class break-word-class plates-margin-left">
                    {{ shipmentData?.transport?.plates ? shipmentData.transport.plates : 'loadPlanTags.doesNotApply' | translate }}
                  </p>
                </div>
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">{{ 'loadPlanTags.trailer1' |
                    translate }}
                  </p>
                  <p class="subtitle-class">{{ shipmentData?.transport?.trailer[0]?.plates ?? 'loadPlanTags.doesNotApply' | translate }}</p>
                </div>
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">{{ 'loadPlanTags.trailer2' | translate }}
                  </p>
                  <p class="subtitle-class">{{ shipmentData?.transport?.trailer[1]?.plates ?? 'loadPlanTags.doesNotApply' | translate }}</p>
                </div>
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">{{ 'loadPlanTags.driver' | translate }}</p>
                  <p class="subtitle-class break-word-class">{{ shipmentData?.transport?.driver ? shipmentData.transport.driver :
                    'loadPlanTags.doesNotApply' | translate }}
                  </p>
                </div>
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">{{ 'loadPlanTags.helper' | translate }}</p>
                  <p class="subtitle-class break-word-class">{{ shipmentData?.transport?.helper ? shipmentData.transport.helper :
                    'loadPlanTags.doesNotApply' | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Orders from shipment data section -->
        <p class="title-header-class compact-characters-size bold-font-class main-accent-color">{{ 'loadPlanTags.ordersUppercase' | translate }}</p>

        <div class="order-data-section-class">
          <div class="container-100-percent">
            <div class="center-items-class">
              <div class="flex-class center-items-class">
                <div class="container-15-percent order-id-header-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.orderId' | translate }} </span>
                </div>
                <div class="container-15-percent orders-headers-first-page-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.account' | translate }} </span>
                </div>
                <div class="container-14-percent orders-headers-first-page-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.invoice' | translate }} </span>
                </div>
                <div class="container-14-percent orders-headers-first-page-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.deliveryDate' | translate }}
                  </span>
                </div>
                <div class="container-14-percent orders-headers-first-page-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.pallets' | translate }} </span>
                </div>
                <div class="container-14-percent orders-headers-first-page-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.boxes' | translate }} </span>
                </div>
                <div class="container-14-percent orders-headers-first-page-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.pieces' | translate }} </span>
                </div>
              </div>
            </div>

            <div *ngFor="let stop of ordersData[0].data">
              <div class="border-accent-color-class stop-data-section">
                <p class="title-header-class compact-characters-size bold-font-class stop-data-margin"> {{ loadPlanService.setStopData(stop) }} </p>
              </div>

              <div class="orders-section">
                <div class="orders-data">
                  <div *ngFor="let order of stop.orders" class="row">
                    <div class="flex-class center-items-class main-margin">
                      <div class="container-15-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div                        
                        [ngClass]="{'compact-characters-size': loadPlanService.hasPropertyTooManyCharacters(order.identifier)}"
                        class="title-header-class compact-characters-size"> {{ order.identifier }} </div>
                      </div>
                      <div
                        class="container-15-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div class="title-header-class compact-characters-size"> {{ order.account.name }} </div>
                      </div>
                      <div
                        class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div
                          [ngClass]="{'compact-characters-size': loadPlanService.hasPropertyTooManyCharacters(order.invoice)}"
                          class="title-header-class compact-characters-size"> {{ order.invoice ? order.invoice : 'loadPlanTags.noInfo' | translate }}
                        </div>
                      </div>
                      <div
                        class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div class="title-header-class compact-characters-size"> {{ loadPlanService.setDeliveryDate(order) }} </div>
                      </div>
                      <div
                        class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div class="title-header-class compact-characters-size"> {{ order.pallets | number:'1.0':'en-US' }} </div>
                      </div>
                      <div
                        class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div class="title-header-class compact-characters-size"> {{ order.boxes | number:'1.0':'en-US' }} </div>
                      </div>
                      <div class="container-14-percent orders-data-class center-text center-content">
                        <div class="title-header-class compact-characters-size"> {{ order.pieces | number:'1.0':'en-US' }} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-30-percent" *ngIf="!shouldHideRequirementsSection">
        <!-- Shipment requirements info section -->
        <div class="third-header-section-class-horizontal">
          <div class="container-90-percent main-margin">
            <p class="title-header-class compact-characters-size bold-font-class">{{ 'loadPlanTags.requirements' | translate }}</p>
          </div>

          <div class="container-90-percent main-margin" *ngIf="shipmentData.specialRequirements?.requirements.length">
            <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.specials' | translate }} </span>
            <span *ngFor="let request of shipmentData.specialRequirements?.requirements"> • {{ request }} </span>
          </div>
          
          <div class="container-90-percent main-margin" *ngIf="!shipmentData.specialRequirements?.requirements.length">
            <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.specials' | translate }} </span>
            <span> {{ 'loadPlanTags.noInfo' | translate }} </span>
          </div>

          <div class="container-90-percent requirements-margin">
            <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.additionals' | translate }} </span>
            <span class="disable-overflow-text">
              {{ shipmentData.specialRequirements?.otherRequirements ? shipmentData.specialRequirements.otherRequirements :
              'loadPlanTags.noInfo' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="container-100-percent-no-info flex-class" *ngIf="shouldHideRequirementsSection">
      <div class="container-100-percent-no-info">
        <!-- General data and total merchandise info section -->
        <div class="first-header-section-class">
          <div class="container-50-percent">
            <div>
              <div class="general-data-title">
                <p class="title-header-class compact-characters-size bold-font-class header-first-section-title-margin">
                  {{ 'loadPlanTags.generalData' | translate }}</p>
              </div>
              <div class="flex-class">
                <div class="container-33-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">
                    {{ 'loadPlanTags.accounts' | translate }}
                  </p>
                  <p class="subtitle-class">{{ shipmentService.setAllAccountsInShipment(fullOrdersData) }}</p>
                </div>
                <div class="container-33-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">
                    {{'loadPlanTags.tripType' | translate }}
                  </p>
                  <p class="subtitle-class">{{ loadPlanService.setShipmentTripTypeLabel(shipmentData.tripType) }}</p>
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">
                    {{ 'loadPlanTags.internalReference' | translate }}</p>
                  <p class="subtitle-class break-word-class">
                    {{ shipmentData.internalReference ?shipmentData.internalReference : 'loadPlanTags.noInfo' | translate }}
                  </p>
                </div>
                <div class="container-33-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">
                    {{ 'loadPlanTags.shipmentServiceType' | translate }}
                  </p>
                  <p class="subtitle-class">
                    {{ loadPlanService.setShipmentServiceType(shipmentData.shipmentServiceType) }}
                  </p>
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">
                    {{ 'loadPlanTags.shipmentLoadType'| translate }}
                  </p>
                  <p class="subtitle-class">{{ loadPlanService.setShipmentLoadType(shipmentData.loadType) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="container-50-percent">
            <div>
              <div class="general-data-title">
                <p class="title-header-class compact-characters-size bold-font-class header-first-section-title-margin">
                  {{ 'loadPlanTags.totalMerchandise' | translate }}
                </p>
              </div>
              <div class="flex-class align-items-to-end merchandise-icons-top-margin">
                <div class="container-25-percent center-text">
                  <img class="merchandise-no-reqs-icon-class" src="{{ stopsIcon }}" alt="Icon for stops">
                  <div class="merchandise-font-class">
                    {{ !loadPlanService.isAGreatNumericValue(shipmentData.ordersByStop.length) ?
                    (shipmentData.ordersByStop.length | number:'1.0':'en-US') :
                    loadPlanService.handleBigQuantities(shipmentData.ordersByStop.length) }}
                  </div>
                  <p class="merchandise-font-margin-class">{{ 'loadPlanTags.stops' | translate }}</p>
                </div>
                <div class="container-25-percent center-text">
                  <img class="merchandise-no-reqs-icon-class" src="{{ ordersIcon }}" alt="Icon for orders">
                  <div class="merchandise-font-class">
                    {{ !loadPlanService.isAGreatNumericValue(shipmentData.orders.length) ?
                    (shipmentData.orders.length | number:'1.0':'en-US') :
                    loadPlanService.handleBigQuantities(shipmentData.orders.length) }}
                  </div>
                  <p class="merchandise-font-margin-class">{{ 'loadPlanTags.orders' | translate }}</p>
                </div>
                <div class="container-25-percent center-text">
                  <img class="merchandise-no-reqs-icon-class" src="{{ palletsIcon }}" alt="Icon for pallets">
                  <div class="merchandise-font-class">
                    {{ !loadPlanService.isAGreatNumericValue(shipmentData.pallets) ?
                    (shipmentData.pallets | number:'1.0':'en-US') :
                    loadPlanService.handleBigQuantities(shipmentData.pallets) }}
                  </div>
                  <p class="merchandise-font-margin-class">{{ 'loadPlanTags.pallets' | translate }}</p>
                </div>
                <div class="container-25-percent center-text">
                  <img class="merchandise-no-reqs-icon-class" src="{{ boxesIcon }}" alt="Icon for boxes">
                  <div class="merchandise-font-class">
                    {{ !loadPlanService.isAGreatNumericValue(shipmentData.boxes) ?
                    (shipmentData.boxes | number:'1.0':'en-US') :
                    loadPlanService.handleBigQuantities(shipmentData.boxes) }}
                  </div>
                  <p class="merchandise-font-margin-class">{{ 'loadPlanTags.boxes' | translate }}</p>
                </div>
                <div class="container-25-percent center-text">
                  <img class="merchandise-no-reqs-icon-class" src="{{ piecesIcon }}" alt="Icon for pieces">
                  <div class="merchandise-font-class">
                    {{ !loadPlanService.isAGreatNumericValue(shipmentData.pieces) ?
                    (shipmentData.pieces | number:'1.0':'en-US') :
                    loadPlanService.handleBigQuantities(shipmentData.pieces) }}
                  </div>
                  <p class="merchandise-font-margin-class">{{ 'loadPlanTags.pieces' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Shipment transport info section -->
        <div class="second-header-section-class">
          <div class="container-100-percent main-margin">
            <div>
              <div class="general-data-title">
                <p class="title-header-class compact-characters-size bold-font-class header-second-section-title-margin">
                  {{ 'loadPlanTags.assignedTransportation' | translate }}
                </p>
              </div>
              <div class="flex-class">
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">
                    {{ 'loadPlanTags.carrier' | translate }}
                  </p>
                  <p class="subtitle-class">
                    {{ shipmentData?.transport?.transportCarrier ?
                    shipmentData.transport.transportCarrier :
                    'loadPlanTags.doesNotApply' | translate }}
                  </p>
                </div>
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">{{
                    'loadPlanTags.vehicleType' | translate }}</p>
                  <p class="subtitle-class">
                    {{ shipmentData?.transport?.vehicle ?
                    shipmentData?.transport?.vehicle :
                    'loadPlanTags.doesNotApply' | translate }}
                  </p>
                </div>
                <div class="container-10-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin plates-margin-left">
                    {{ 'loadPlanTags.plates' | translate }}
                  </p>
                  <p class="subtitle-class break-word-class plates-margin-left">
                    {{ shipmentData?.transport?.plates ? shipmentData.transport.plates : 'loadPlanTags.doesNotApply' | translate }}
                  </p>
                </div>
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">
                    {{ 'loadPlanTags.trailer1' |translate }}
                  </p>
                  <p class="subtitle-class">
                    {{ shipmentData?.transport?.trailer[0]?.plates ?? 'loadPlanTags.doesNotApply' | translate }}
                  </p>
                </div>
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">
                    {{ 'loadPlanTags.trailer2' | translate }}
                  </p>
                  <p class="subtitle-class">
                    {{ shipmentData?.transport?.trailer[1]?.plates ?? 'loadPlanTags.doesNotApply' |translate }}
                  </p>
                </div>
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">
                    {{ 'loadPlanTags.driver' | translate }}
                  </p>
                  <p class="subtitle-class break-word-class">
                    {{ shipmentData?.transport?.driver ? shipmentData.transport.driver : 'loadPlanTags.doesNotApply' | translate }}
                  </p>
                </div>
                <div class="container-15-percent">
                  <p class="title-header-class compact-characters-size bold-font-class general-title-margin">
                    {{ 'loadPlanTags.helper' | translate }}
                  </p>
                  <p class="subtitle-class break-word-class">
                    {{ shipmentData?.transport?.helper ? shipmentData.transport.helper : 'loadPlanTags.doesNotApply' | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Orders from shipment data section -->
        <p class="title-header-class compact-characters-size bold-font-class main-accent-color">
          {{ 'loadPlanTags.ordersUppercase' | translate }}
        </p>

        <div class="order-data-section-class">
          <div class="container-100-percent">
            <div class="center-items-class">
              <div class="flex-class center-items-class">
                <div class="container-15-percent order-id-header-class extra-margins-for-headers border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class">
                    {{ 'loadPlanTags.orderId' | translate }}
                  </span>
                </div>
                <div class="container-15-percent orders-headers-first-page-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class">
                    {{ 'loadPlanTags.account' | translate }}
                  </span>
                </div>
                <div class="container-14-percent orders-headers-first-page-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class">
                    {{ 'loadPlanTags.invoice' | translate }}
                  </span>
                </div>
                <div class="container-14-percent orders-headers-first-page-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class">
                    {{ 'loadPlanTags.deliveryDate' | translate }}
                  </span>
                </div>
                <div class="container-14-percent orders-headers-first-page-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class">
                    {{ 'loadPlanTags.pallets' | translate }}
                  </span>
                </div>
                <div class="container-14-percent orders-headers-first-page-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class">
                    {{ 'loadPlanTags.boxes' | translate }}
                  </span>
                </div>
                <div class="container-14-percent orders-headers-first-page-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class">
                    {{ 'loadPlanTags.pieces' | translate }}
                  </span>
                </div>
              </div>
            </div>

            <div *ngFor="let stop of ordersData[0].data">
              <div class="border-accent-color-class stop-data-section">
                <p class="title-header-class compact-characters-size bold-font-class stop-data-margin">
                  {{ loadPlanService.setStopData(stop) }}
                </p>
              </div>
              <div class="orders-section">
                <div class="orders-data">
                  <div *ngFor="let order of stop.orders" class="row">
                    <div class="flex-class center-items-class main-margin">
                      <div
                        class="container-15-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div
                          [ngClass]="{'compact-characters-size': loadPlanService.hasPropertyTooManyCharacters(order.identifier)}"
                          class="title-header-class compact-characters-size">
                          {{ order.identifier }}
                        </div>
                      </div>
                      <div
                        class="container-15-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div class="title-header-class compact-characters-size">
                          {{ order.account.name }}
                        </div>
                      </div>
                      <div
                        class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div
                          [ngClass]="{'compact-characters-size': loadPlanService.hasPropertyTooManyCharacters(order.invoice)}"
                          class="title-header-class compact-characters-size">
                          {{ order.invoice ? order.invoice : 'loadPlanTags.noInfo' | translate }}
                        </div>
                      </div>
                      <div
                        class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div class="title-header-class compact-characters-size">
                          {{ loadPlanService.setDeliveryDate(order) }}
                        </div>
                      </div>
                      <div
                        class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div class="title-header-class compact-characters-size">
                          {{ order.pallets | number:'1.0':'en-US' }}
                        </div>
                      </div>
                      <div
                        class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                        <div class="title-header-class compact-characters-size">
                          {{ order.boxes | number:'1.0':'en-US' }}
                        </div>
                      </div>
                      <div class="container-14-percent orders-data-class center-text center-content">
                        <div class="title-header-class compact-characters-size">
                          {{ order.pieces | number:'1.0':'en-US' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-30-percent" *ngIf="!shouldHideRequirementsSection">
        <!-- Shipment requirements info section -->
        <div class="third-header-section-class-horizontal">
          <div class="container-90-percent main-margin">
            <p class="title-header-class compact-characters-size bold-font-class">
              {{ 'loadPlanTags.requirements' | translate }}
            </p>
          </div>

          <div class="container-90-percent main-margin" *ngIf="shipmentData.specialRequirements?.requirements.length">
            <span class="title-header-class compact-characters-size bold-font-class">
              {{ 'loadPlanTags.specials' | translate}}
            </span>
            <span *ngFor="let request of shipmentData.specialRequirements?.requirements"> • {{ request }} </span>
          </div>

          <div class="container-90-percent main-margin" *ngIf="!shipmentData.specialRequirements?.requirements.length">
            <span class="title-header-class compact-characters-size bold-font-class">
              {{ 'loadPlanTags.specials' | translate}}
            </span>
            <span> {{ 'loadPlanTags.noInfo' | translate }} </span>
          </div>

          <div class="container-90-percent requirements-margin">
            <span class="title-header-class compact-characters-size bold-font-class">
              {{ 'loadPlanTags.additionals' | translate }} </span>
            <span class="disable-overflow-text">
              {{ shipmentData.specialRequirements?.otherRequirements ? shipmentData.specialRequirements.otherRequirements :
              'loadPlanTags.noInfo' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let page of ordersData; let position = index">
      <div class="pagebreak" *ngIf="position > 0"></div>
      <div class="order-data-section-class" *ngIf="position > 0">
        <div class="container-100-percent">
          <div class="center-items-class">
            <div class="flex-class center-items-class">
              <div class="container-15-percent order-id-header-class extra-margins-for-headers border-right-dashed-class center-text">
                <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.orderId' | translate }} </span>
              </div>
              <div class="container-15-percent orders-headers-class border-right-dashed-class center-text">
                <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.account' | translate }} </span>
              </div>
              <div class="container-14-percent orders-headers-class border-right-dashed-class center-text">
                <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.invoice' | translate }} </span>
              </div>
              <div class="container-14-percent orders-headers-class border-right-dashed-class center-text">
                <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.deliveryDate' | translate }} </span>
              </div>
              <div class="container-14-percent orders-headers-class border-right-dashed-class center-text">
                <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.pallets' | translate }} </span>
              </div>
              <div class="container-14-percent orders-headers-class border-right-dashed-class center-text">
                <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.boxes' | translate }} </span>
              </div>
              <div class="container-14-percent orders-headers-class center-text">
                <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.pieces' | translate }} </span>
              </div>
            </div>
          </div>

          <div *ngFor="let stop of page.data">
            <div class="border-accent-color-class stop-data-section">
              <p class="title-header-class compact-characters-size bold-font-class stop-data-margin"> {{ loadPlanService.setStopData(stop) }} </p>
            </div>

            <div class="orders-section">
              <div class="orders-data">
                <div *ngFor="let order of stop.orders" class="row">
                  <div class="flex-class center-items-class main-margin">
                    <div
                      class="container-15-percent orders-data-class border-right-dashed-class center-text center-content">
                      <div
                        class="title-header-class compact-characters-size"> {{ order.identifier }}
                      </div>
                    </div>
                    <div
                      [ngClass]="{'compact-characters-size': loadPlanService.hasPropertyTooManyCharacters(product?.name)}"
                      class="container-15-percent orders-data-class border-right-dashed-class center-text center-content">
                      <div class="title-header-class compact-characters-size"> {{ order.account.name }} </div>
                    </div>
                    <div
                      class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                      <div
                        class="title-header-class compact-characters-size"> {{ order.invoice ? order.invoice : 'loadPlanTags.noInfo' | translate }}
                      </div>
                    </div>
                    <div
                      class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                      <div class="title-header-class compact-characters-size"> {{ loadPlanService.setDeliveryDate(order) }} </div>
                    </div>
                    <div
                      class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                      <div class="title-header-class compact-characters-size"> {{ order.pallets | number:'1.0':'en-US' }} </div>
                    </div>
                    <div
                      class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                      <div class="title-header-class compact-characters-size"> {{ order.boxes | number:'1.0':'en-US' }} </div>
                    </div>
                    <div class="container-14-percent orders-data-class center-text center-content">
                      <div class="title-header-class compact-characters-size"> {{ order.pieces | number:'1.0':'en-US' }} </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Products from orders section -->
    <div *ngIf="ordersProductsData?.length">
      <p class="title-header-class compact-characters-size bold-font-class main-accent-color">{{ 'loadPlanTags.products' | translate }}</p>
      
      <div *ngFor="let page of ordersProductsData">
        <div class="order-data-section-class">
          <div class="container-100-percent">
            <div class="center-items-class">
              <div class="flex-class center-items-class">
                <div
                  class="container-15-percent order-id-header-class padding-top-8px center-text border-right-dashed-class">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.productCode' | translate }} </span>
                </div>
                <div class="container-28-percent orders-headers-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.description' | translate }} </span>
                </div>
                <div class="container-14-percent orders-headers-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.unitMeasure' | translate }} </span>
                </div>
                <div class="container-14-percent orders-headers-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.quantity' | translate }} </span>
                </div>
                <div class="container-14-percent orders-headers-class border-right-dashed-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.weight' | translate }} </span>
                </div>
                <div class="container-14-percent orders-headers-class center-text">
                  <span class="title-header-class compact-characters-size bold-font-class"> {{ 'loadPlanTags.volume' | translate }} </span>
                </div>
              </div>
            </div>
  
            <div *ngFor="let order of page.data">
              <div class="border-accent-color-class stop-data-section" *ngIf="order.products?.length">
                <p class="title-header-class compact-characters-size bold-font-class stop-data-margin">
                  {{ 'loadPlanTags.orderId' | translate }}: {{ order.identifier }} -
                  {{ 'loadPlanTags.account' | translate }}: {{ order.account.name }}
                </p>
              </div>
  
              <div class="products-section" *ngIf="order.products?.length">
                <div class="products-data">
                  <div *ngIf="!order.products?.length" class="products">
                    <div class="row">
                      <div>
                        <div class="flex-class center-items-class main-margin">
                          <div class="container-100-percent-no-info orders-data-class center-text center-content">
                            <div class="title-header-class compact-characters-size"> {{ 'loadPlanTags.noInfo' | translate }} </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="order.products?.length" class="products">
                    <div class="row" *ngFor="let product of order.products">
                      <div class="flex-class center-items-class main-margin">
                        <div
                          class="container-15-percent orders-data-class border-right-dashed-class center-text center-content">
                          <div class="title-header-class compact-characters-size">
                            {{ product.code ? product.code : 'loadPlanTags.noInfo' | translate }}
                          </div>
                        </div>
                        <div
                          class="container-28-percent orders-data-class border-right-dashed-class center-text center-content">
                          <div class="title-header-class compact-characters-size">
                            {{ product.name ? product.name : 'loadPlanTags.noInfo' | translate }}
                          </div>
                        </div>
                        <div
                          class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                          <div class="title-header-class compact-characters-size">
                            {{ product.unitKey ? product.unitKey : 'loadPlanTags.noInfo' | translate }}
                          </div>
                        </div>
                        <div
                          class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                          <div class="title-header-class compact-characters-size">
                            {{ product.total ? (product.total | number:'1.0':'en-US') : 'loadPlanTags.noInfo' | translate }}
                          </div>
                        </div>
                        <div
                          class="container-14-percent orders-data-class border-right-dashed-class center-text center-content">
                          <div class="title-header-class compact-characters-size">
                            {{ product.weight ? (product.weight | number:'1.0':'en-US') : 'loadPlanTags.noInfo' | translate }}
                          </div>
                        </div>
                        <div class="container-14-percent orders-data-class center-text center-content">
                          <div class="title-header-class compact-characters-size">
                            {{ product.volume ? (product.volume | number:'1.0':'en-US') : 'loadPlanTags.noInfo' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="page.shouldApplyBreakPage" class="pagebreak"></div>
      </div>
    </div>
    
    <!-- Final section -->
    <div class="receiver-section">
      <div class="container-25-percent receiver-class signature-height">
        <p class="receiver-signature-class">{{ 'loadPlanTags.additionalComments' | translate }}</p>
        
      </div>
      <div class="container-65-percent receiver-class flex-class">
        <div class="container-30-percent">
          <p class="receiver-signature-class">{{ 'loadPlanTags.supervisorySignature' | translate }}</p>
        </div>
        <hr class="separator-class">
        <div class="container-70-percent">
          <p class="receiver-signature-class">
            {{ 'loadPlanTags.carrierAcceptance' | translate }}
            {{ shipmentData?.transport?.transportCarrier ? shipmentData.transport.transportCarrier : 'loadPlanTags.noInfo' | translate }}
          </p>
        </div>
        
      </div>
      <div class="container-10-percent QR-container">
        <qrcode
          [qrdata]="shipmentData.shipmentId"
          [width]="80"
          [errorCorrectionLevel]="'M'"
          [elementType]="'svg'">
        </qrcode>
      </div>
    </div>
  </div>
</div>