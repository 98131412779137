import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { ChartModule } from 'angular2-chartjs';
import { CommonModule } from '@angular/common';
import { CovalentSearchModule  } from '@covalent/core/search';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxPrintModule } from 'ngx-print';
import { QRCodeModule } from 'angularx-qrcode';
// import { TagInputModule } from 'ngx-chips';
import { TranslateModule } from '@ngx-translate/core';

import { RatingModule } from 'primeng/rating';
import { DialogModule } from 'primeng/dialog';

import { BillingSheetService } from '../../services/billing-sheet.service';
import { BoolToWordPipe } from './../../pipes/bool-to-word.pipe';
import { CardWhiteGuidesComponent } from '../../components/card-white-guides/card-white-guides.component';
import { CarouselComponent } from '../../components/carousel/carousel.component';
import { CarrierGroupProvider } from '../../providers/carrier-group/carrier-group.provider.service';
import { ChartGraphComponent } from '../../components/chart-graph/chart-graph.component';
import { CorrectMisspellingPipe } from '../../pipes/correct-misspelling.pipe';
import { CustomerInvoicePdfComponent } from '../../components/customer-invoice-pdf/customer-invoice-pdf.component';
import { DateFormatPipe } from '../../pipes/date-format.pipe';
import { DateFormatService } from '../../services/date-format.service';
import { DeliveryDetailComponent } from '../../components/delivery-detail/delivery-detail.component';
import {
  DialogAssignCarriersComponent
} from '../../components/dialog/dialog-marketplace/dialog-assign-carriers/dialog-assign-carriers.component';
import {
  DialogAssociateShipmentComponent
} from '../../components/dialog/dialog-marketplace/dialog-associate-shipment/dialog-associate-shipment.component';
import { DialogDynamicLocationComponent } from '../../components/dialog/dialog-dynamic-location/dialog-dynamic-location.component';
import { DialogLoadPlanComponent } from '../../components/dialog/dialog-load-plan/dialog-load-plan.component';
import {
  DialogStatusChangesConfirmationComponent
} from '../../components/dialog/dialog-status-changes-confirmation/dialog-status-changes-confirmation.component';
import {
  DialogStatusChangesWarningComponent
} from '../../components/dialog/dialog-status-changes-warning/dialog-status-changes-warning.component';
import { DownloadFilesService } from '../../services/utils/download-files.service';
import { DragAndDropComponent } from '../../components/drag-and-drop/drag-and-drop.component';
import { EvidenceFolioFormatComponent } from '../../pages/evidence/evidence-folio-format/evidence-folio-format.component';
import { EvidencesService } from '../../services/evidences/evidences.service';
import {
  EvidenceReleaseFolioDocumentComponent
} from '../../pages/evidence/evidence-release-folio-document/evidence-release-folio-document.component';
import { ExcelService } from '../../services/utils/excel-tools.service';
import { ExcelServiceReport } from '../../pages/reports/costing-sheets-report/utils/excel-tools.service';
import { FreightInvoicesDetailsComponent } from '../../components/freight-invoices-details/freight-invoices-details.component';
import { GeoLocationService } from '../../services/utils/get-position.service';
import { HomeComponent } from '../../pages/home/home.component';
import { IndicatorsProvider } from '../../providers/indicators/indicators-provider.service';
import { InformationBandComponent } from '../../components/information-band/information-band.component';
import { LanguageTranslateService } from '../../services/translate/language-translate.service';
import { LazyTranslateModule } from './lazy-translate.module';
import { LoadPlanComponent } from '../../pages/shipments/load-plan/load-plan.component';
import { LoadPlanLandscapeVersionComponent } from '../../components/load-plan/load-plan-landscape-version/load-plan-landscape-version.component';
import { LoadPlanPortraitVersionComponent } from '../../components/load-plan/load-plan-portrait-version/load-plan-portrait-version.component';
import { LoadPlanService } from '../../services/load-plan/load-plan.service';
import { ManualDeliveryComponent } from '../../pages/evidence/manual-delivery/manual-delivery.component';
import { MapLocationComponent } from '../../components/map-location/map-location.component';
import { MapsProvider } from '../../providers/maps/maps-provider.service';
import { MarketplaceService } from '../../services/marketplace/marketplace.service';
import { MaterialModule } from '../material/material.module';
import { MiniCarouselComponent } from '../../components/mini-carousel/mini-carousel.component';
import { MultipleInputSearchComponent } from '../../components/multiple-input-search/multiple-input-search.component';
import { NetPromoterScoreComponent } from '../../components/net-promoter-score/net-promoter-score.component';
import { NoDataComponent } from '../../components/no-data/no-data.component';
import { NoDataPipe } from './../../pipes/no-data.pipe';
import { NoInfoComponent } from '../../components/no-info/no-info.component';
import { NumberOnlyDirectiveModule } from '../directives/number-only-directive.module';
import { OrderLabelViewComponent } from '../../components/order-label-view/order-label-view.component';
import { OrderBulkLoadErrorHandlerService } from '../../services/order/order-bulk-load-error-handler.service';
import { OrderService } from '../../services/order/order.service';
import { RateComponent } from '../../components/marketplace/rate/rate.component';
import {
  RegisterFreightInvoicesPdfComponent } from '../../components/register-freight-invoices-pdf/register-freight-invoices-pdf.component';
import { ReportFilterSearchComponent } from '../../components/report-filter-search/report-filter-search.component';
import { ResponseInterceptorProvider } from '../../providers/response-interceptor/response-interceptor.service';
// import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { ScfChartModule, ScfCardModule, ScfExcelImportModule, ScfGridModule, ScfLoaderModule, ScfQuickFilterModule } from 'scf-library';
import { SearsOrderDataService } from '../../services/order/share-sears-order-data.service';
import { SearchFilterComponent } from '../../components/search-filter/search-filter.component';
import { SelectSearchComponent } from '../../components/select-search/select-search.component';
import { ShareLoadPlanService } from '../../services/share-load-data/share-load-plan.service';
import { ShipmenDataTranslateService } from '../../services/translate/shipment-data-translate.service';
import { ShipmentCommunicationService } from '../../services/shipment.service';
import { ShipmentService } from '../../services/shipment/shipment.service';
import { StarRateComponent } from '../../components/star-rate/star-rate.component';
import { StarRatingComponent } from '../../components/marketplace/star-rating/star-rating.component';
import { ShipmentCardInfoComponent } from '../../components/shipment-card-info/shipment-card-info.component';
import {
  ShipmentRequestFormatComponent
} from '../../components/dialog/dialog-shipment-request-format/dialog-shipment-request-format.component';
import { ShipmentsService } from '../../providers/shipments/shipments.service';
import { StepperDynamicComponent } from '../../components/stepper-dynamic/stepper-dynamic.component';
import { SubtitleDividerComponent } from '../../components/subtitle-divider/subtitle-divider.component';
import { TaxStampCofeprisService } from '../../services/tax-stamp/tax-stamp-cofepris-service.service';
import { TokenInterceptorProvider } from '../../providers/token-interceptor/token-interceptor.service';
import { UserWarehouseComponent } from '../../components/user-warehouse/user-warehouse.component';

const sharedComponents = [
  BoolToWordPipe,
  CardWhiteGuidesComponent,
  CarouselComponent,
  ChartGraphComponent,
  CorrectMisspellingPipe,
  CustomerInvoicePdfComponent,
  DateFormatPipe,
  DeliveryDetailComponent,
  DialogAssignCarriersComponent,
  DialogAssociateShipmentComponent,
  DialogDynamicLocationComponent,
  DialogLoadPlanComponent,
  DialogStatusChangesConfirmationComponent,
  DialogStatusChangesWarningComponent,
  DragAndDropComponent,
  EvidenceFolioFormatComponent,
  EvidenceReleaseFolioDocumentComponent,
  FreightInvoicesDetailsComponent,
  HomeComponent,
  InformationBandComponent,
  LoadPlanComponent,
  LoadPlanLandscapeVersionComponent,
  LoadPlanPortraitVersionComponent,
  ManualDeliveryComponent,
  MapLocationComponent,
  MiniCarouselComponent,
  MultipleInputSearchComponent,
  NetPromoterScoreComponent,
  NoDataComponent,
  NoDataPipe,
  NoInfoComponent,
  OrderLabelViewComponent,
  RateComponent,
  RegisterFreightInvoicesPdfComponent,
  ReportFilterSearchComponent,
  SearchFilterComponent,
  SelectSearchComponent,
  ShipmentCardInfoComponent,
  ShipmentRequestFormatComponent,
  StarRateComponent,
  StarRatingComponent,
  StepperDynamicComponent,
  SubtitleDividerComponent,
  UserWarehouseComponent
];

const sharedModules = [
  AgmCoreModule,
  AgmDirectionModule,
  ChartModule,
  CommonModule,
  CovalentSearchModule,
  DialogModule,
  FormsModule,
  HttpClientModule,
  MatDialogModule,
  MaterialModule,
  NgxBarcodeModule,
  NgxDropzoneModule,
  NgxMatSelectSearchModule,
  NgxPrintModule,
  NumberOnlyDirectiveModule,
  QRCodeModule,
  RatingModule,
  ReactiveFormsModule,
  // SatDatepickerModule,
  // SatNativeDateModule,
  ScfCardModule,
  ScfChartModule,
  ScfLoaderModule,
  ScfGridModule,
  ScfQuickFilterModule,
  ScfExcelImportModule,
  // TagInputModule
];

const sharedProviders = [
  BillingSheetService,
  CarrierGroupProvider,
  DateFormatService,
  DownloadFilesService,
  ExcelService,
  ExcelServiceReport,
  EvidencesService,
  GeoLocationService,
  IndicatorsProvider,
  LoadPlanService,
  MarketplaceService,
  MapsProvider,
  OrderService,
  OrderBulkLoadErrorHandlerService,
  SearsOrderDataService,
  ShareLoadPlanService,
  ShipmenDataTranslateService,
  ShipmentCommunicationService,
  ShipmentService,
  ShipmentsService,
  TaxStampCofeprisService,
  LanguageTranslateService,
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorProvider, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptorProvider, multi: true}
];

const exportsModules = [
  ...sharedComponents, ...sharedModules
];

const exportEntryComponents = [
  DialogDynamicLocationComponent
];

@NgModule({
  declarations: sharedComponents,
  entryComponents: exportEntryComponents,
  exports: [...exportsModules, TranslateModule],
  imports: [...sharedModules, LazyTranslateModule],
  providers: sharedProviders
})

/**
 * Shared module.
 */
export class SharedModule { }
