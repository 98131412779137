<div class="general-container" *ngIf="!showOffers">
  <h3 class="title-page">{{ labels.title }}</h3>
  <mat-divider class="mat-divider-header"></mat-divider>
  <div class="card-list" >
    <mat-card
      *ngFor="let shipments of shipmentFilters, let i=index"
      (click)="getShipperShipmentsByStatus(shipments, i)"
      class="card-list-item quick-filter-container"
      [ngClass]="{'card-selected': quickFilterSelected && i === quickFilterIndex}">
        <div class="gds-item-value flex-sm-container">
          {{ shipments.shipments ? shipments.shipments.length : 0 }} 
        </div>
        <div class="gds-item-label gds-container flex-md-container">
          <span>{{ labels.descriptionCard }} </span> <span class="spn-bold">{{ shipments.description }}</span> 
        </div>
        <div class="flex-sm-container">
          <div class="container-point">
            <mat-icon class="gds-circle gds-circle-not-initiated"  [style.color]="shipments.color">
              fiber_manual_record
            </mat-icon>
          </div>
        </div>
    </mat-card>
  </div>
  <app-no-info *ngIf="!dataAvailable" [message]='message'></app-no-info>
  <div class="shipmentsRequest-container" *ngIf="dataAvailable">
    <div class="filter-container">
      <mat-form-field>
        <span matPrefix>
          <mat-icon class="icons">
            <img src="{{ searchIcon }}"  alt="search icon"/>
          </mat-icon> &nbsp;
        </span>
        <input
          matInput
          id="searchFilter"
          (keyup)="applyFilter($event.target.value)"
          [placeholder]="labels.searchPlaceholder">
      </mat-form-field>
    </div>
    <app-shipment-card-info
      *ngFor="let item of shipmentRequests" 
      [shipmentRequests]="item"
      [isBiisPerson]="isBiisPerson"
      [userCoordinates]="coordinates"
      (shipmentSelected)="goToOffersShipment($event)">
    </app-shipment-card-info>
  </div>
</div>

<div class="general-container" *ngIf="showOffers">
  <h3 class="title-page">{{ labels.offersTitle }}</h3>
  <mat-divider class="mat-divider-header margin-header"></mat-divider>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="expandedStatus">
    <mat-expansion-panel-header collapsedHeight='2.5em' expandedHeight='2.5em'>
      <mat-panel-title>
        {{ labels.requestData }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-grid-list cols="4" rowHeight="40px">
      <mat-grid-tile colspan="4">
        <div class="container-subtitle">
          <div>
            <h2 class="subtitle"> {{ labels.dateShipmentData }}</h2>
          </div>
          <hr class="hr-subtitle">
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="4">
        <div class="container-paragraph">
          <div class="paragraph-inline mr-15 width-c-3">
            <div class="dot-description">
              &bull;
            </div>
            <div class="paragraph-description">
              <div>
                {{ labels.dateOrigin }}
              </div>
              <div class="text-custom max-w-310 ml-5">
                <span class="element-decription">{{ shipmentSelected.origin.loadDate ?
                  (shipmentSelected.origin.loadDate | date: onlyDateFormat | titlecase) : labels.noInfo  }}</span>
              </div>
            </div>
          </div>
          <div class="paragraph-inline mr-15 width-c-3">
            <div class="dot-description">
              &bull;
            </div>
            <div class="paragraph-description">
              <div>
                {{ labels.timeOrigin }}
              </div>
              <div class="text-custom max-w-150 ml-5">
                <span class="element-decription">{{ originTime ? originTime : labels.noInfo }}</span>
              </div>
            </div>
          </div>
          <div class="paragraph-inline-destination-date mr-15 width-c-3">
            <div class="dot-description">
              &bull;
            </div>
            <div class="paragraph-description">
              <div>
                {{ labels.dateDestination }}
              </div>
              <div class="text-custom max-w-150 ml-5">
                <span class="element-decription">{{ shipmentSelected.destinations.length ?
                  (shipmentSelected.destinations[shipmentSelected.destinations.length - 1].deliveryDate | date: onlyDateFormat | titlecase)
                  : labels.noInfo  }}</span>
              </div>
            </div>
          </div>
          <div class="paragraph-inline mr-15 width-c-3">
            <div class="dot-description">
              &bull;
            </div>
            <div class="paragraph-description">
              <div>
                {{ labels.timeDestination }}
              </div>
              <div class="text-custom max-w-150 ml-5">
                <span class="element-decription">{{ destinationTime ? destinationTime : labels.noInfo }}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="4">
        <div class="container-subtitle-route">
          <div>
            <h2 class="subtitle-route"> {{ labels.routeTitle }}</h2>
          </div>
          <hr class="hr-subtitle-route">
        </div>
        <div class="container-subtitle-deliveries-date">
          <div>
            <h2 class="subtitle-deliveries-date"> {{ labels.deliveriesRate }}</h2>
          </div>
          <hr class="hr-subtitle-deliveries-date">
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="4">
        <div class="container-paragraph">
          <div class="paragraph-inline-total-distance mr-15 width-c-3">
            <div class="dot-description">
              &bull;
            </div>
            <div class="paragraph-description">
              <div>
                {{ labels.totalDistance }}
              </div>
              <div class="text-custom max-w-310 ml-5">
                <span class="element-decription">{{ distanceRoute ? distanceRoute : labels.noInfo }}</span>
              </div>
            </div>
          </div>
          <div class="paragraph-inline-delivery mr-15 width-c-3">
            <div class="dot-description">
              &bull;
            </div>
            <div class="paragraph-description">
              <div>
                {{ labels.deliveries }}
              </div>
              <div class="text-custom max-w-150 ml-5">
                <span class="element-decription">{{ shipmentSelected.destinations.length > 1 ? shipmentSelected.destinations.length :
                  labels.withoutDeliveries }}</span>
              </div>
            </div>
          </div>
          <div class="paragraph-inline-total mr-15 width-c-3">
            <div class="dot-description">
              &bull;
            </div>
            <div class="paragraph-description">
              <div>
                {{ labels.budget }}
              </div>
              <div class="text-custom max-w-310 ml-5">
                <span class="element-decription">{{ shipmentSelected.defaultOffer ? labels.dollarSign + (shipmentSelected.defaultOffer | number:'1.0':'en-US') : labels.noInfo }}</span>
              </div>
            </div>
          </div>
          <div class="paragraph-inline-expiration mr-15 width-c-3">
            <div class="dot-description">
              &bull;
            </div>
            <div class="paragraph-description">
              <div>
                {{ labels.expirationDate }}
              </div>
              <div class="text-custom max-w-150 ml-5">
                <span class="element-decription">{{ shipmentSelected.expirationDate ? (shipmentSelected.expirationDate | date: onlyDateFormat | titlecase) : labels.noInfo }}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-expansion-panel>
  <h3 class="title-page">{{ shipmentsOffer.length ? labels.selectOffers : labels.noInfo }}</h3>
  <div *ngIf="shipmentsOffer.length" class="scf-flex-row scf-flex-wrap">
    <mat-card class="offers-card" *ngFor ="let offer of shipmentsOffer; let indexPosition = index">
      <mat-card-content>
        <mat-grid-list cols="2" rowHeight="50">
          <mat-grid-tile>
            <mat-card-subtitle class="acent-color">{{ displayCarrierName ? offer.carrier.name : labels.offer + (indexPosition + 1) }}</mat-card-subtitle>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-card-subtitle class="acent-color amount-class">${{ offer.offer | number:'1.0':'en-US' }}</mat-card-subtitle>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="2:1">
          <mat-grid-tile>
            <span>
              <app-star-rate
                [rating]="offer.carrier.rate ? offer.carrier.rate : 0" 
                [starCount]="starCount">
              </app-star-rate>
            </span>
          </mat-grid-tile>
          <mat-grid-tile>
            <button
              id="selectOfferButton"
              class="button-acent-color"
              (click)="onConfirm(offer)"
              mat-stroked-button
              color="primary">
              {{ labels.select }}
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>
  <button
    id="backButton"
    mat-stroked-button
    color="primary"
    (click)="hideOffersShipment()"
    class="secondary-button-cancel">
    {{ labels.goBack }}
  </button>
</div>
